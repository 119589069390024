.App {
	text-align: center;
	background-color: black;
	height: 100vh;
	overflow-y: scroll;
	overflow-x: hidden;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.h-75vh {
	height: 75vh;
}

.white-container {
	background-color: white;
	border-radius: 70px 0 70px 0;
	height: 75vh;
}
.bgGrey {
	background-color: #f5f5f5 !important ;
}

.button-number-watches {
	background-color: black;
	border: 1px solid white;
	border-radius: 15%;
	min-height: 50px;
	max-height: 100px;
	min-width: 50px;
	max-width: 100px;
	color: white;
}

.button-number-watches-active {
	background-color: white;
	color: black;
}

.white-button {
	background-color: white;
}

.white-text {
	color: white !important;
}

.black-text {
	color: black;
}

.red-button {
	background-color: red;
}

.blue-button {
	background-color: #0067ff;
}

.black-button {
	background-color: black !important;
}

.custom-button {
	border-radius: 25px 0 25px 0;
	border: 0.1px solid black;
	height: 50px;
	width: 100%;
	font-size: 22px;
	text-align: center;
}

.custom-buttonB {
	border-radius: 25px 0 25px 0 !important;
	border: 0.1px solid black !important;
	height: 50px;
	width: 100%;
	font-size: 22px;
	text-align: center;
	box-shadow: none !important;
}

.icon-button {
	border-radius: 25px;
	border: 0.1px solid black;
	margin: 10px;
	height: 45px;
	width: 70px;
}

.icons-container {
	position: absolute;
	bottom: 0;
	margin-bottom: 2rem;
	align-items: center;
	justify-content: center;
}

.video-container {
	position: sticky;
	z-index: 50;
	min-width: 0;
}

.video-chat-container {
	width: 100%;
}

.remote-video {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	max-height: 500px;
}

.local-video {
	position: absolute;
	top: 15px;
	left: 30%;
	width: 100%;
	max-height: 120px;
	z-index: 50;
}

::-webkit-media-controls {
	display: none !important;
}

video::-webkit-media-controls {
	display: none !important;
}
/* class="sizing-small phase-pre-ready state-no-source"
 */
.custom-container {
	border-radius: 25px;
	border: 0.1px solid white;
	height: 50px;
	min-width: 200px;
	max-width: 400px;
	width: 100%;
	font-size: 22px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: white;
}

.custom-container:hover {
	color: gray;
}

.custom-container-text {
	border: none;
	margin: 1rem;
}

.back-button {
	background-color: transparent;
	border: none;
	color: white;
	position: absolute;
	top: 10px;
	left: 10px;
}
.back-button div {
	display: flex;
	align-items: center;
	font-weight: 300;
	font-size: 16px;
}
.menu-button {
	background-color: transparent;
	border: none;
	color: white;
	position: absolute;
	top: 6px;
	right: 2px;
}

h2 {
	font-weight: 300;
}

.image-upload > input {
	display: none;
}
.upload-icon {
	width: 45px;
	height: 45px;
	border-radius: 8px;
	background-color: lightgrey;
}
.upload-icon img {
	width: 100%;
	height: 100%;
	margin: 0px;
	border-radius: 8px;
	cursor: pointer;
}
.upload-icon.has-img {
	width: 100%;
	height: 100%;
	border: none;
}
.upload-icon.has-img img {
	width: 100%;
	height: auto;
	border-radius: 18px;
	margin: 0px;
}
.form-check-input {
	margin-left: 0px !important;
	height: 25px;
	width: 45px !important;
	margin-right: 8px;
}
.react-datepicker__input-container input {
	width: 100%;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	line-height: 1.5;
	padding: 0.375rem 0.75rem;
}

.react-datepicker {
	display: inline-flex !important;
}

.menu {
	width: 100%;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	display: none;
	z-index: 100;
}
.menu.active {
	display: inline-block;
}
.menu button {
	color: white;
}
.menu .row {
	background-color: white;
}
.close {
	right: 10px;
	top: 10px;
}
.close svg {
	height: 50px;
	width: 50px;
}

.caps {
	text-transform: uppercase;
}

.text-bold {
	font-weight: bold;
}

.margin-bottom-2 {
	margin-bottom: 2rem;
}

.margin-top-2 {
	margin-top: 2rem;
}

.margin-horizontal {
	margin-left: 1rem;
	margin-right: 1rem;
}

.center-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 90%;
}

.calendar-list-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: white;
}

.calendar-container {
	width: auto;
	margin: 10px;
	padding: 1rem;
	background: none;
	border-radius: 5px;
	border: 1px solid black;
	border-width: 0 0 2px 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.calendar-container:hover {
	background: #f2f2f2;
}
